import React, { useRef, useCallback, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

/* Material Ui */
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PersonIcon from '@material-ui/icons/Person';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import IconButton from '@material-ui/core/IconButton';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';

/* Components */
import SideBar from './SideBar';
import NibMenu from '../common/NibMenu/NibMenu';

/* Utils. */
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

/* Assets */
import reveelLogo from '@assets/img/menu/reveel-logo.png';

import noavatar from '../../assets/img/noavatar.svg';

/* Styles */
import css from './appNav.module.sass';
import { _ } from '@helpers';
import AdminToExchangeButton from './AdminToExchangeButton';

const AppNav = ({ user, isSideBarOpen, setSideBar, logout, toggleHelp }) => {
  let history = useHistory();
  const { isHelpActive, platformSettings } = useSelector((state) => state.app);

  const [menuOpen, setmenu] = useState(false);
  const [companyLogo, setCompanyLogo] = useState(reveelLogo);

  const openProfile = () => {
    setmenu(!menuOpen);
  };

  const signout = async () => {
    try {
      await logout();
      history.push('/login');
    } catch (error) {
      console.log(error);
      toast.error('error on logout');
    }
  };

  const getAvatarSrc = () => {
    return user && user.avatar ? `${process.env.REACT_APP_API_URL}${user.avatar.url}` : noavatar;
  };

  useEffect(() => {
    if (platformSettings?.logoUrl) {
      setCompanyLogo(platformSettings.logoUrl);
    }
  }, [platformSettings]);

  if (!user) return <></>;

  const rolesDisabled = !!Number(process.env.REACT_APP_DISABLE_ROLES);
  const userRolesText = !rolesDisabled && <>{user.roles.map((role) => _.capitalize(role.name)).join(', ')}</>;

  const handleMainSearchKeyDownEnter = (e) => {
    if (e.key === 'Enter') {
      alert('NO FUNCTION');
      console.log(this);
    }
  };

  return (
    <>
      <SideBar open={isSideBarOpen} user={user} getAvatarSrc={getAvatarSrc} userRolesText={userRolesText} />
      <header className={css.header + ' appHeader'}>
        <div
          className={css.SideBarBurger + ' ' + (isSideBarOpen ? css.SideBarBurgerOpen : '')}
          onClick={() => {
            setSideBar(!isSideBarOpen);
            setTimeout(function () {
              window.dispatchEvent(new Event('resize'));
            }, 500);
          }}
        >
          <div className={css.SideBarBurgerImage}></div>
        </div>
        <div className={css.HeaderLogo} onClick={() => history.push('/dashboard')}>
          <img src={companyLogo} alt="logo" />
        </div>
        <div className={css.headerSearchWrapper}>
          <AdminToExchangeButton />
          <div className={css.HeaderSearchBlock + ' HeaderSearchBlock'}>
            <div className={css.HeaderSearchInputBlock}>
              <div className={css.HeaderSearchIcon}></div>
              <input name="main_search" onKeyDown={handleMainSearchKeyDownEnter} placeholder="Search..." />
            </div>
            <div className={css.rightTools}>
              <IconButton onClick={toggleHelp}>
                <HelpOutlineOutlinedIcon className={isHelpActive ? css.active : ''} />
              </IconButton>
            </div>
            <div className={css.userProfile}>
              <div
                onClick={openProfile}
                className={css.userAvatar}
                style={{ backgroundImage: `url(${getAvatarSrc()})` }}
              >
                <NibMenu open={menuOpen}>
                  <List component="nav" aria-label="main mailbox folders">
                    <ListItem button onClick={() => history.push('/user-profile')}>
                      <ListItemIcon>
                        <PersonIcon style={{ color: '#fff' }} />
                      </ListItemIcon>
                      <ListItemText primary="Profile" />
                    </ListItem>
                    <ListItem button onClick={signout}>
                      <ListItemIcon>
                        <ExitToAppIcon style={{ color: '#fff' }} />
                      </ListItemIcon>
                      <ListItemText primary="Logout" />
                    </ListItem>
                  </List>
                </NibMenu>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default AppNav;
