import API from '@api';
import React, { useState, useEffect, useRef } from 'react';
/* Material UI */
import { Typography, Checkbox, TextField, FormControlLabel } from '@material-ui/core';

/* Redux */
import { connect, useSelector } from 'react-redux';
import { getSession } from '../../redux/session-reducer';

/* Components */
import NibButton from '../../components/common/NibButton/NibButton';
import NibLink from '../../components/common/NibLink/NibLink';
import NibPasswordInput from '../../components/common/NibPasswordInput';
import NibLoader from '@components/common/NibLoader/NibLoader';

/* Utils */
import { useLocation, useHistory } from 'react-router-dom';

/* Styles */
import css from './loginPage.module.sass';
import * as QRCode from 'easyqrcodejs';
import logo from '@assets/img/logo.png';

const LoginPage = ({ getSession, ...props }) => {
  let [login, setLogin] = useState('');
  let [pass, setPass] = useState('');
  let [loading, setLoading] = useState(true);
  const code = useRef();
  let history = useHistory();
  const returnUrl = new URLSearchParams(useLocation().search).get('return');

  const { platformSettings } = useSelector((state) => state.app);
  const isQrCodeLogo = Boolean(platformSettings?.qrCodeLogo);

  useEffect(() => {
    if (!loading && process.env.REACT_APP_LOGIN_PAGE_QR_CODE_LINK) {
      const text = process.env.REACT_APP_LOGIN_PAGE_QR_CODE_LINK;
      let options = {
        text,
        logo: platformSettings?.qrCodeLogo || logo,
        width: 140,
        height: 140,
        colorDark: '#3d3d3d',
        colorLight: '#cecece',
        quietZone: 1,
        quietZoneColor: 'white',
        logoWidth: 40,
        logoHeight: 40,
        logoBackgroundColor: isQrCodeLogo ? 'transparent' : '#3d3d3d',
        logoBackgroundTransparent: isQrCodeLogo,
        correctLevel: QRCode.CorrectLevel.M,
      };
      if (code) {
        new QRCode(code.current, { ...options });
      }
    }
  }, [code, platformSettings?.qrCodeLogo, loading, isQrCodeLogo]);

  useEffect(() => {
    (async function () {
      try {
        const user = await API.user.getMe();
        if (user) {
          window.location.href = '/dashboard';
        }
      } catch (error) {
        setLoading(false);
      }
    })();
  }, []);

  const onLogin = async (e) => {
    e.preventDefault();
    let session = await getSession(login, pass);
    if (session) {
      returnUrl ? (window.location.href = returnUrl) : history.push('/dashboard');
    }
  };
  return (
    <div className={css.content}>
      {loading ? (
        <NibLoader line={false} />
      ) : (
        <div className={css.main}>
          <div className={css.block}>
            <div className={css.logo}></div>
            <div className={css.loginInfo}>
              <Typography className={css.signIn}>SIGN IN</Typography>
              <div className={css.info}>
                <Typography>Login with {platformSettings.companyName} wallet</Typography>
                <Typography className={css.infoWallet}>
                  Open QR code scanner in wallet and scan code to log in.
                </Typography>
                <div style={{ marginTop: 10 }} ref={code} />
              </div>
              <Typography className={css.logIn}>Login with {platformSettings.companyName} ID</Typography>
              <div className={css.loginForm}>
                <TextField
                  onChange={(e) => setLogin(e.target.value)}
                  value={login}
                  placeholder="Email or Username"
                  variant={'outlined'}
                />
                <NibPasswordInput placeholder="Password" label="" onChange={setPass} value={pass} />
                <FormControlLabel value="top" control={<Checkbox />} label="Remember me" labelPlacement="left" />
                <NibButton
                  className={css.loginBtn}
                  mui
                  variant="outlined"
                  size="large"
                  label={'Login'}
                  onClick={onLogin}
                />
              </div>
              <div className={css.links}>
                <p>
                  <NibLink to="/password-reset">Forgot password?</NibLink>
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default connect(null, { getSession })(LoginPage);
